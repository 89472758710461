import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import LogoAlt from "../images/svg/logo-alt.svg";
import Close from "../images/svg/close-blue.svg";
import SocialMediaIcons from "./SocialMediaIcons";

const FullScreenMenuOverlay = ({ onClickClose }) => {
  const { t } = useTranslation();

  return (
    <div className="h-full w-full fixed z-50 left-0 top-0 bg-screamingGreen overflow-x-hidden">
      <div className="flex flex-col relative">
        <div className="flex h-18 container px-8 mx-auto justify-between flex-row items-center relative">
          <Link to="/">
            <LogoAlt />
          </Link>
          <div>
            <button onClick={onClickClose}>
              <Close />
            </button>
          </div>
        </div>
        <div className="flex flex-col items-center pb-20 pt-10 justify-between">
          <Link
            style={LinkStyleMobileMenu}
            className="block"
            to="/"
            onClick={onClickClose}
          >
            Home
          </Link>
          <Link
            to="/innovation/"
            style={LinkStyleMobileMenu}
            className="block mt-5"
          >
            {t("innovation")}
          </Link>
          <Link
            to="/produkt/"
            style={LinkStyleMobileMenu}
            className="block mt-5"
          >
            {t("product")}
          </Link>
          <Link to="/about/" style={LinkStyleMobileMenu} className="block mt-5">
            {t("about")}
          </Link>
          <Link
            to="/kontakt/"
            style={LinkStyleMobileMenu}
            className="block mt-5"
          >
            {t("contact")}
          </Link>
        </div>
        <div className="flex flex-col items-center">
          <SocialMediaIcons alternate />
        </div>
      </div>
    </div>
  );
};

const LinkStyleMobileMenu = {
  fontSize: "26px",
  lineHeight: "36px",
  color: "#140E55",
  textDecoration: "none",
  fontFamily: "Regular",
};

export default FullScreenMenuOverlay;
