import React from "react";
import PropTypes from "prop-types";

const Container = ({ children, backgroundColor }) => (
  <div style={{ backgroundColor }}>
    <div className={`container mx-auto py-20 px-8`}>{children}</div>
  </div>
);

Container.propTypes = {
  backgroundColor: PropTypes.string,
};

Container.defaultProps = {
  backgroundColor: "#fff",
};

export default Container;
