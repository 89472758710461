import React, { useState, useEffect } from "react";

const Scroll = ({
  direction = `up`,
  by,
  to,
  showBelow,
  className,
  size = `1.7em`,
}) => {
  if (![`up`, `down`].includes(direction))
    throw TypeError(
      `Scroll component's direction prop must be either 'up' or 'down'`
    );
  if (to && (typeof to !== `number` || to <= 0))
    throw TypeError(`Scroll component's to prop must be a positive number`);
  if (by && typeof by !== `number`)
    throw TypeError(`Scroll component's by prop must be a number`);

  const [show, setShow] = useState(showBelow ? false : true);

  const scroll = ({ mode, to }) =>
    window[`scroll` + mode]({ top: to, behavior: `smooth` });

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const handleClick = () => {
    if (to) scroll({ mode: `To`, to: to * window.innerHeight });
    else if (by) scroll({ mode: `By`, to: by * window.innerHeight });
    else if (direction === `up`) scroll({ mode: `To`, to: 0 });
    else scroll({ mode: `To`, to: document.body.scrollHeight });
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  const arrowProps = { show, direction, className, size };
  return (
    <div className={`scrollTop ${show ? "flex lg:hidden" : "hidden"}`}>
      <svg
        className="cursor-pointer scrollarrowicon"
        width="40px"
        height="40px"
        viewBox="0 0 90 90"
        version="1.1"
        onClick={handleClick}
      >
        <title>Nach oben scrollen</title>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="noun_Arrow_138547" fill="#00FC64" fill-rule="nonzero">
            <path
              d="M45,0 C20.2,0 0,20.2 0,45 C0,69.8 20.2,90 45,90 C69.8,90 90,69.8 90,45 C90,20.2 69.8,0 45,0 Z M59.8,57.2 L45,42.4 L30.2,57.2 L23.8,50.8 L45,29.6 L66.2,50.8 L59.8,57.2 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Scroll;
