import React, { useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./selectlangnav.css";
import Icon from "../images/svg/selectlang-icon.svg";
import CheckedLang from "../images/svg/lang-checked.svg";
import { LanguageContext } from "../context";
import useOutsideAlerter from "../utils/useOutsideAlerter";

const LANGUAGES = [
  { title: "DE (Deutsch)", locale: "DE" },
  { title: "EN (English)", locale: "EN" },
  // { title: "FR (français)", locale: "FR" },
  // { title: "ES (español)", locale: "ES" },
  // { title: "IT (italiano)", locale: "IT" },
];

const SelectLangNav = ({ top, alternate }) => {
  const wrapperRef = useRef(null);
  const { changeLanguage, getLanguage } = useContext(LanguageContext);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { i18n } = useTranslation();
  useOutsideAlerter(wrapperRef, setIsDropdownVisible);

  function handleClick(e) {
    const { locale } = e.target.dataset;
    e.preventDefault();
    changeLanguage(locale);
    setIsDropdownVisible(false);
    i18n.changeLanguage(locale.toLowerCase());
  }

  return (
    <div ref={wrapperRef} className="relative inline-block text-left">
      <div>
        <button
          className="outline-none"
          onClick={() => setIsDropdownVisible(!isDropdownVisible)}
        >
          <div
            style={{ height: "24px", width: "42px" }}
            className="flex items-center justify-between"
          >
            <p
              style={{ fontSize: "15px" }}
              className="m-0 leading-none text-white"
            >
              {getLanguage()}
            </p>
            <Icon />
          </div>
        </button>
      </div>

      <div
        style={top && { bottom: 24, right: 32 }}
        className={`origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg ${
          isDropdownVisible ? "block" : "hidden"
        }`}
      >
        <div className="rounded-lg bg-tolopea shadow-xs">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {LANGUAGES.map((lang) => (
              <a
                key={lang.locale}
                href="#"
                onClick={handleClick}
                className={`flex flex-row items-center justify-between block px-3 py-2 menuitemtext hover:text-screamingGreen focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${
                  alternate && "bg-blue-900"
                } ${
                  getLanguage() === lang.locale &&
                  "bg-screamingGreen text-tarawera hover:text-tarawera"
                }`}
                role="menuitem"
                data-locale={lang.locale}
              >
                {lang.title}
                {getLanguage() === lang.locale && <CheckedLang />}
              </a>
            ))}
            {/* <a
              href="#"
              onClick={handleClick}
              class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              role="menuitem"
            >
              EN (english)
            </a>
            <a
              href="#"
              onClick={handleClick}
              class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              role="menuitem"
            >
              DE (deutsch)
            </a>
            <a
              href="#"
              onClick={handleClick}
              class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              role="menuitem"
            >
              FR (français)
            </a>
            <a
              href="#"
              onClick={handleClick}
              class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              role="menuitem"
            >
              ES (español)
            </a>
            <a
              href="#"
              onClick={handleClick}
              class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              role="menuitem"
            >
              IT (italiano)
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectLangNav;
