import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import "./footer.css";
import Container from "../components/Container";
import SelectLangNav from "../components/SelectLangNav";
import Colors from "../utils/colors";
import SocialMediaIcons from "./SocialMediaIcons";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <Container backgroundColor={Colors.tolopea}>
        {/**MOBILE */}
        <div className="lg:hidden">
          <div className="mb-12">
            <div className="h-8 text-screamingGreen font-safiaBold text-body">
              SAFIA Technologies GmbH
            </div>
            <p className="text-s15 text-white">{t("safiaFooterOne")}</p>
            <p className="text-s15 text-white">{t("safiaFooterTwo")}</p>
          </div>
          <div className="mb-12">
            <div className="h-8 text-screamingGreen font-safiaRegular text-body text-s13">
              {t("contact")}
            </div>
            <div className="text-white font-safiaBold text-body">
              SAFIA Technologies GmbH
            </div>
            <p className="text-body text-white">
              {t("bam")}
              <br />
              Richard-Willstätter-Straße 11 <br />
              12489 Berlin, Germany
            </p>
            <div className="text-body text-white">
              T:{" "}
              <a className="no-underline" href="tel:+493081045854">
                +49 162 7323 405
              </a>
              <br />
              M:{" "}
              <a
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#69FF75",
                  textUnderlinePosition: "under",
                  textDecorationThickness: 1,
                }}
                href="mailto:info@safia.tech"
              >
                info@safia.tech
              </a>
            </div>
          </div>
          <div className="mb-12 flex justify-center">
            <SocialMediaIcons />
          </div>
          <div className="text-s13 text-white font-safiaRegular text-center">
            <Link className="no-underline" to="/impressumdatenschutz/">
              {t("imprintPrivacyPolicy")}
            </Link>
          </div>
          <div className="mt-4 text-s13 text-white font-safiaRegular text-center">
            © 2020 SAFIA Technologies GmbH
          </div>
        </div>

        <div style={{ height: 345 }} className="hidden lg:flex flex-wrap">
          <div className="w-5/12 flex flex-col justify-between">
            <div>
              <div className="h-8 text-screamingGreen font-safiaBold text-body">
                SAFIA Technologies GmbH
              </div>
              <p className="text-s15 text-white">{t("safiaFooterOne")}</p>
              <p className="text-s15 text-white">{t("safiaFooterTwo")}</p>
            </div>
            <div className="text-s13 text-white font-safiaRegular">
              © 2020 SAFIA Technologies GmbH{" "}
              <span className="text-screamingGreen">•</span>{" "}
              <Link className="no-underline" to="/impressumdatenschutz/">
                {t("imprintPrivacyPolicy")}
              </Link>
            </div>
          </div>
          {/** DESKTOP */}
          <div className="flex flex-col items-center w-3/12">
            {/** flex-col justify-between */}
            <div className="flex flex-col justify-between h-full">
              <div>
                <div className="h-8 text-screamingGreen font-safiaRegular text-body text-s13">
                  {t("menu")}
                </div>
                <div>
                  <Link
                    className="text-body block text-white no-underline mb-2"
                    to="/"
                  >
                    Home
                  </Link>
                  <Link
                    className="text-body block text-white no-underline mb-2"
                    to="/innovation/"
                  >
                    {t("innovation")}
                  </Link>
                  <Link
                    className="text-body block text-white no-underline mb-2"
                    to="/produkt/"
                  >
                    {t("product")}
                  </Link>
                  <Link
                    className="text-body block text-white no-underline mb-2"
                    to="/about/"
                  >
                    {t("about")}
                  </Link>
                  <Link
                    className="text-body block text-white no-underline"
                    to="/kontakt/"
                  >
                    {t("contact")}
                  </Link>
                </div>
              </div>
              <SelectLangNav top alternate />
            </div>
          </div>
          <div className="flex flex-col justify-between w-4/12">
            <div>
              <div className="h-8 text-screamingGreen font-safiaRegular text-body text-s13">
                {t("contact")}
              </div>
              <div className="text-white font-safiaBold text-body">
                SAFIA Technologies GmbH
              </div>
              <p className="text-body text-white">
                {t("bam")}
                <br />
                Richard-Willstätter-Straße 11 <br />
                12489 Berlin, Germany
              </p>
              <div className="text-body text-white">
                T:{" "}
                <a className="no-underline" href="tel:+493081045854">
                  +49 162 7323 405
                </a>
                <br />
                M:{" "}
                <a
                  style={{
                    textDecoration: "underline",
                    textDecorationColor: "#69FF75",
                    textUnderlinePosition: "under",
                    textDecorationThickness: 1,
                  }}
                  href="mailto:info@safia.tech"
                >
                  info@safia.tech
                </a>
              </div>
            </div>
            <div className="hidden lg:block">
              <SocialMediaIcons />
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
