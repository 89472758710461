import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Logo from "../images/svg/logo.svg";
import HamburgerMenuIcon from "../images/svg/hamburger.svg";
import SelectLangNav from "./SelectLangNav";

import FullScreenMenuOverlay from "./FullScreenMenuOverlay";

// position: absolute; top:0px; bottom:0px; width: 100%

const Header = ({ siteTitle }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  function handleClick(e) {
    e.preventDefault();
    setIsMenuOpen(true);
  }

  if (isMenuOpen) {
    return <FullScreenMenuOverlay onClickClose={() => setIsMenuOpen(false)} />;
  }

  return (
    <header className="bg-transparent absolute top-0 w-full z-50">
      <div className="flex h-18 container px-8 mx-auto justify-between flex-row items-center">
        <Link to="/">
          <Logo />
        </Link>
        <div className="flex items-center">
          <div className="flex-row hidden lg:block mr-16 lg:mr-32">
            <Link
              activeStyle={activeLinkStyle}
              className="headerMenuLink"
              to="/innovation/"
            >
              {t("innovation")}
            </Link>{" "}
            <Link
              activeStyle={activeLinkStyle}
              className="headerMenuLink"
              to="/produkt/"
            >
              {t("product")}
            </Link>{" "}
            <Link
              activeStyle={activeLinkStyle}
              className="headerMenuLink"
              to="/about/"
            >
              {t("about")}
            </Link>{" "}
            <Link
              activeStyle={activeLinkStyle}
              className="mr-0 headerMenuLink"
              to="/kontakt/"
            >
              {t("contact")}
            </Link>
          </div>
          <div className="hidden lg:block">
            <SelectLangNav />
          </div>
        </div>

        {/* <button onClick={() => setIsMenuOpen(true)}> */}
        <div className="flex block lg:hidden items-center">
          <SelectLangNav />
          <div
            style={{
              paddingTop: "2em",
              marginTop: "-2em",
              paddingRight: "2em",
              marginRight: "-2em",
              paddingBottom: "2em",
              marginBottom: "-2em",
            }}
            className="block ml-5 cursor-pointer"
            onClick={handleClick}
          >
            <HamburgerMenuIcon />
          </div>
        </div>
      </div>
    </header>
  );
};

const activeLinkStyle = {
  textDecoration: "underline",
  textDecorationColor: "#69FF75",
  textUnderlinePosition: "below",
  textDecorationThickness: 2,
  textUnderlineOffset: "5px",
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
