/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

import Header from "./header";
import Footer from "./footer";
import ScrollArrow from "./ScrollArrow";
import "./layout.css";
import { LanguageContext } from "../context";

const Layout = ({ children }) => {
  const { t } = useTranslation();
  const { getLanguage } = useContext(LanguageContext);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <Header />
        {children}
      </div>
      <ScrollArrow showBelow={2000} />
      <CookieConsent
        expires={30}
        buttonClasses="font-safiaRegular"
        buttonText={t("understand")}
        buttonStyle={{
          background: "#fff",
          border: "0",
          borderRadius: "40px",
          boxShadow: "none",
          color: "#140E55",
          cursor: "pointer",
          flex: "0 0 auto",
          padding: "5px 15px",
          margin: "15px",
        }}
        style={{ background: "#3C2BFF" }}
      >
        {getLanguage() === "DE" ? (
          <span className="font-safiaRegular text-white">
            {t("cookieText")}{" "}
            <Link to="/impressumdatenschutz/">
              {t("datenschutzrichtlinien")}
            </Link>{" "}
            <span className="font-safiaRegular text-white">
              {t("cookieText2")}
            </span>
          </span>
        ) : (
          <span className="font-safiaRegular text-white">
            {t("cookieText")}{" "}
            <Link to="/impressumdatenschutz/">
              {t("datenschutzrichtlinien")}
            </Link>{" "}
          </span>
        )}
      </CookieConsent>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
