import React from "react";
import { graphql, useStaticQuery } from "gatsby";

const SocialMediaIcons = ({ alternate }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          twitter
          linkedin
          xing
          youtube
        }
      }
    }
  `);
  const { linkedin, twitter, xing, youtube } = data.site.siteMetadata;
  return (
    <div className="flex flex-row items-center">
      <a
        style={{ marginRight: "35px" }}
        href={twitter}
        target="_blank"
        rel="noreferrer"
      >
        <svg width="27px" height="20px" viewBox="0 0 27 20" version="1.1">
          <title>Twitter</title>
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRrule="evenodd"
          >
            <g
              id="Group-3-Copy-4"
              transform="translate(0.000000, -4.000000)"
              className={alternate ? "socialmediaiconalt" : "socialmediaicon"}
            >
              <path
                d="M8.49099644,24 C18.6795793,24 24.2513808,16.303813 24.2513808,9.63192194 C24.2513808,9.41309508 24.2513808,9.19613059 24.2350382,8.97916609 C25.3197776,8.26495249 26.2574336,7.37660858 27,6.36348681 C25.9888023,6.772274 24.9152985,7.04138447 23.8162594,7.15778173 C24.9755618,6.52737416 25.8386548,5.53660066 26.2543694,4.36517861 C25.1665658,4.95368317 23.9755996,5.3689886 22.7345842,5.59060899 C20.6386472,3.5578472 17.1300976,3.46100468 14.8993342,5.37178214 C13.4591435,6.60466194 12.8503821,8.44373868 13.2957176,10.1980782 C8.8413407,9.99321904 4.69236589,8.07499216 1.88041916,4.92109194 C0.410607551,7.22762009 1.15930241,10.1803858 3.59434819,11.6628214 C2.7138912,11.6376796 1.85079821,11.4207151 1.07963229,11.0296203 L1.07963229,11.0948027 C1.08269653,13.4991046 2.94064462,15.5691135 5.52379511,16.0449455 C4.70870848,16.2470112 3.85378679,16.2768088 3.02235757,16.1324762 C3.74960278,18.1875863 5.83021866,19.5973899 8.19887266,19.6364994 C6.23674056,21.0425784 3.81293032,21.8080068 1.32068548,21.8052132 C0.877392752,21.8024197 0.43818567,21.7800714 0,21.7297878 C2.53310131,23.2122233 5.48293864,24 8.49099644,23.9944129"
                id="Fill-9"
              ></path>
            </g>
          </g>
        </svg>
      </a>
      <a
        style={{ marginRight: "35px" }}
        href={linkedin}
        target="_blank"
        rel="noreferrer"
      >
        <svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1">
          <title>LinkedIn</title>
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRrule="evenodd"
          >
            <g
              id="Group-3-Copy-4"
              transform="translate(-62.000000, -4.000000)"
              className={alternate ? "socialmediaiconalt" : "socialmediaicon"}
            >
              <path
                d="M63,24 L67,24 L67,11 L63,11 L63,24 Z M64.5014021,9 C63.117966,9 62,7.87902019 62,6.49813014 C62,5.12004488 63.117966,4 64.5014021,4 C65.8792298,4 67,5.12004488 67,6.49813014 C67,7.87902019 65.8792298,9 64.5014021,9 L64.5014021,9 Z M83,24 L78.5130701,24 L78.5130701,17.3571804 C78.5130701,15.7738033 78.4819244,13.736586 76.1800612,13.736586 C73.8441324,13.736586 73.4869299,15.4608101 73.4869299,17.2421094 L73.4869299,24 L69,24 L69,10.3406102 L73.307842,10.3406102 L73.307842,12.2075224 L73.3672136,12.2075224 C73.9667686,11.1332194 75.429644,10 77.6147108,10 C82.1590656,10 83,12.830747 83,16.5074961 L83,24 Z"
                id="Fill-5"
              ></path>
            </g>
          </g>
        </svg>
      </a>
      <a href={youtube} target="_blank" rel="noreferrer">
        <div style={{ color: "red", fill: "red" }}>
          <svg width="27px" height="19px" viewBox="0 0 27 19" version="1.1">
            <title>YouTube</title>
            <g
              id="Symbols"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRrule="evenodd"
            >
              <g
                id="Socia-Media-Group"
                transform="translate(-178.000000, -5.000000)"
              >
                <g id="Group" transform="translate(178.000000, 5.000000)">
                  <path
                    className={
                      alternate ? "socialmediaiconalt" : "socialmediaicon"
                    }
                    d="M26.4423499,2.97331574 C26.1321057,1.81321015 25.220899,0.894066338 24.0623648,0.582233444 C21.9492331,0 13.4977912,0 13.4977912,0 C13.4977912,0 5.04960365,0 2.93647198,0.558246299 C1.80180272,0.873350166 0.86781588,1.81321015 0.556486933,2.97331574 C0,5.09726845 0,9.49890968 0,9.49890968 C0,9.49890968 0,13.927809 0.556486933,16.0277746 C0.86781588,17.1889705 1.77902255,18.1081143 2.93647198,18.4199472 C5.07021428,19 13.4977912,19 13.4977912,19 C13.4977912,19 21.9492331,19 24.0623648,18.4406634 C25.220899,18.1277402 26.1321057,17.2129576 26.4423499,16.0506714 C27,13.927809 27,9.52289682 27,9.52289682 C27,9.52289682 27.0194474,5.09726845 26.4423499,2.97331574"
                    id="Fill-1"
                  ></path>
                  <polygon
                    id="Fill-3"
                    fill={alternate ? "#69FF75" : "#101850"}
                    ill-opacity="0.0"
                    points="18 10 11 6 11 14"
                  ></polygon>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </a>
    </div>
  );
};

export default SocialMediaIcons;
